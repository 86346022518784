/* -------------------------------------------------------------------------- *
 * Modal Window for YouTube Embeds *
 * -------------------------------------------------------------------------- */

const settings = {
    modal: '#js-videoModal',
    iframe: '#js-videoModalEmbed',
    close_btn: '#js-videoModalClose',
    trigger_btn: '.js-videoModalBtn',
};

let modal;
let iframe;
let close_btn;

function closeVideoModal() {
    // Stop the video from playing
    iframe.src = 'about:blank';

    modal.classList.remove('is-open');
}

class VideoModalButton {
    constructor(el) {
        this.el = el;

        this.el.addEventListener('click', (e) => {
            e.preventDefault();
            this.openVideoModal();
        });
    }

    openVideoModal() {
        // Update the iframe source
        iframe.src = this.el.href;

        modal.classList.add('is-open');
    }
}

/* ------------- *
 * Module Export *
 * ------------- */

const VideoModal = {
    selector: settings.trigger_btn,

    init(selector = settings.trigger_btn) {
        const buttons = document.querySelectorAll(selector);
        const button_arr = Array.from(buttons);

        button_arr.forEach((el) => new VideoModalButton(el));

        modal = document.querySelector(settings.modal);
        iframe = document.querySelector(settings.iframe);

        close_btn = document.querySelector(settings.close_btn);

        modal.addEventListener('click', closeVideoModal);
        close_btn.addEventListener('click', closeVideoModal);
    },
};

export default VideoModal;
