/* -------------------------------------------------------------------------- *
 * JavaScript Modules *
 * -------------------------------------------------------------------------- */

import Toggle from './modules/toggle';
import VideoModal from './modules/videomodal';

/* -------------------------------------------------------------------------- *
 * Module Initialization *
 * -------------------------------------------------------------------------- */

if (document.querySelector(Toggle.selector) !== null) {
    Toggle.init();
}

if (document.querySelector(VideoModal.selector) !== null) {
    VideoModal.init();
}
